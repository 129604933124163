// eslint-disable-next-line import/no-cycle
import ajax from '../../ajax';

export default {
  namespaced: true,

  state: {
    integrateData: {}
  },

  mutations: {
    set(state, data) {
      state.integrateData = data;
    },
  },

  actions: {
    get({ commit }, params) {
      return ajax.get('/api/v1/fulfill-integrate/prqs', { params: params })
        .then((response) => {
          commit('set', response.data);
        })
        .catch((errors) => { return Promise.reject(errors.response.data) });
    },

    post({ commit }, params) {
      const url = '/api/v1/fulfill-integrate/projects/0';

      return ajax.put(url, params)
        .then((response) => {
          return response.data;
        })
        .catch((error) => { return Promise.reject(error.response.data) });
    },

    async getAttachment({ commit }, params) {
      const url = `/api/v1/fulfill-integrate/attachments/${params.id}`;

      return ajax.get(url, { responseType: 'blob', ...params })
        .then((response) => {
          console.log(response);
          console.log(params);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', params.fileName);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => Promise.reject(error));
    },

    postAttachment({ commit }, params) {
      const url = '/api/v1/fulfill-integrate/attachments';

      return ajax.post(url, params,{
        headers: {
          'Content-Type': 'multipart/form-data;',
        }
      }).then((response) => {
          return response.data;
        })
      .catch((error) => { return Promise.reject(error.response.data) });
    },

    deleteAttachment({ commit }, params) {
        const url = `/api/v1/fulfill-integrate/attachments/${params.id}`;

      return ajax.delete(url, { params: params })
        .then((response) => {
            return response.data;
        })
        .catch((error) => { return Promise.reject(error.response.data) });
    },
  },
};
