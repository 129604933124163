import ajax from '../../ajax';

export default {
  namespaced: true,

  state: {
    contacts: [],
    contacts_fields: []
  },
  mutations: {
    set_contacts(state, contacts) {
      state.contacts = contacts;
    },
    set_contacts_fields(state, contacts_fields) {
      state.contacts_fields = contacts_fields;
    },
    add_contact(state, contact) {
      state.contacts.push(contact);
    },
    update_contact(state, updatedContact) {
      const index = state.contacts.findIndex(contact => contact.Id === updatedContact.id);
      if (index !== -1) {
        state.contacts.splice(index, 1, updatedContact);
      }
    }
  },
  actions: {
    fetchContacts({ commit }) {
      return ajax.get('/api/v1/contacts')
        .then((response) => {
          commit('set_contacts', response.data.contacts);
          return response.data.data;
        })
        .catch((errors) => {
          console.error('Error fetching contacts:', errors);
        });
    },
    fetchContactsFields({ commit }) {
      return ajax.get('/api/v1/contacts/contact_field_info')
        .then((response) => {
          commit('set_contacts_fields', response.data.data);
          return response.data.data;
        })
        .catch((errors) => {
          console.error(errors);
        });
    },
    createContact({ commit }, contact) {
      return ajax.post('/api/v1/contacts', contact)
        .then((response) => {
          commit('add_contact', response.data[0]);
          return response.data;
        })
        .catch((errors) => {
          console.error('Error creating contact:', errors);
        });
    },
    updateContact({ commit }, contact) {
      return ajax.put(`/api/v1/contacts/${contact.Id}`, contact)
        .then((response) => {
          commit('update_contact', response.data[0]);
          return response.data;
        })
        .catch((errors) => {
          console.error('Error updating contact:', errors);
        });
    }
  },
  getters: {
    contacts: state => state.contacts
  }
};
