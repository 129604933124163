import { render, staticRenderFns } from "./applicationCard.vue?vue&type=template&id=544b797e&scoped=true"
import script from "./applicationCard.vue?vue&type=script&lang=js"
export * from "./applicationCard.vue?vue&type=script&lang=js"
import style0 from "./applicationCard.vue?vue&type=style&index=0&id=544b797e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "544b797e",
  null
  
)

export default component.exports