<template>
  <div class="detail-section">
    <div class="m-2 pl-3 text-center" v-show="isLoading">
      <font-awesome-icon
        :icon="['fa', 'circle-notch']"
        class="fa-spin m-auto spinner fa-5x"
      />
    </div>
    <template v-show="!isLoading">
      <ValidationObserver
        tag="form"
        @submit.prevent=""
        ref="prqSave"
        class="detail-section-form"
      >
        <div v-for="section in fetchedData" :key="section.id">
          <prq :section="section" @answerList="addToAnswerList" v-if="section.question_type === 'Question' && isPrq"/>
          <attachment :section="section" @answerList="addToAnswerList" v-if="section.question_type === 'Attachment' && isAttachment"/>
        </div>
        <div class="d-flex justify-content-end mt-4" v-if="!isLoading">
          <button
            type="submit"
            class="btn btn-egx mr-4"
            @click="saveAnswer('offersPage')"
          >
            Save &amp; Return to My Applications Page
          </button>
          <button
            type="submit"
            class="btn btn-egx"
            @click="saveAnswer('step')"
          >
            Save &amp; Next Step
          </button>
        </div>
      </ValidationObserver>
    </template>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import Prq from './Prqs.vue';
import Attachment from './Attachment.vue';
import GroupQuestion from '../../configurations/settings/group-questions.json';

export default {
  name: 'DetailSection',

  components: {
    Multiselect,
    Prq,
    Attachment
  },

  props: {
    content: Object,
  },

  data() {
    return {
      responses: Object,
      query: this.$route.query,
      errMsg: '',
      isLoading: true,
      prqData: GroupQuestion,
      fetchedData: [],
      allAnswerList: {},
    };
  },

  created() {
    this.$store.dispatch('fulfill_integrate/get', this.query).then(() => {
      console.log('Success!');
      this.fetchedData = this.$store.state.fulfill_integrate.integrateData;
      this.isLoading = false;
    }).catch((error) => {
      console.log(error.message);
      this.$notify({
        group: 'notice',
        text: error.message,
      });
    });

    this.fetchedData =
      this.fetchedData.sort(
        (a, b) => a.sorting_tag.localeCompare(b.sorting_tag, 'en', { numeric: true })
      );
  },

  computed: {
    isPrq() {
      return this.query.type === 'prq';
    },

    isAttachment() {
      return this.query.type === 'attachment';
    }
  },

  methods: {
    saveAnswer(nextPage) {
      this.$refs.prqSave.validate()
        .then((isValid) => {
          if (isValid) {
            this.isLoading = true;
            console.log(this.allAnswerList);
            const data = {
              answerList: this.allAnswerList,
              details: this.query
            }
            this.$store.dispatch('fulfill_integrate/post', data).then(() => {
              console.log('Success!');
              this.$notify({
                group: 'notice',
                text: 'Update Successful.',
              });
              // const newQuery = { ...this.query };
              // newQuery.status = 'testing2';
              // this.$router.push({path: 'test-page', query: newQuery }).catch(()=>{});
            }).catch((error) => {
              console.log(error.message);
              this.$notify({
                group: 'notice',
                text: error.message,
              });
            }).finally(() => {
              this.isLoading = false;
            });
          } else {
            console.log('Not Validated.');
          }
        });

      if (nextPage === 'step') {
        this.$emit('navigate', this.content.nextStepUrl);
      }
    },

    addToAnswerList(groupId, answerObject) {
      if(this.allAnswerList[groupId] == null){
        this.allAnswerList[groupId] = answerObject;
      } else {
        this.allAnswerList[groupId] = { ...this.allAnswerList[groupId], ...answerObject };
      }
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
