<template>
<div :class="['card', { 'no-card': disableCard }]">
  <div :class="['card-body', { 'no-card': disableCard }]" v-for="(application, index) in applications">
    <div class="row">
      <div class="col-lg-8">
        <div>
          <span class="egx-partner-color">
            <h4>{{ application.title }}</h4>
          </span>
        </div>
        <div>
          <b>Application id: </b>
          <span>{{ application.id }}</span>
        </div>
        <div>
          <b>Status: </b>
          <span>{{ application.status }}</span>
        </div>
        <div>
          <p>{{ application.description }}</p>
        </div>
        <div class="mt-4">
          <b>Last Updated: </b>
          <span>{{ application.lastUpdated }}</span>
        </div>
      </div>
      <div class="col-lg-4 d-flex justify-content-center align-items-center">
        <button class="btn btn-warning" style="height: 3rem; width: 10rem;" @click="updateApplication(application.id)">Update</button>
      </div>
    </div>
    <hr v-if="index < applications.length - 1" />
  </div>
</div>
</template>

<script>
import mockData from '../mockData';

export default {
  components: {
  },
  data() {
    return {
      applications: mockData.application.applications,
      disableCard: false
    };
  },
  computed: {
  },
  methods: {
    updateApplication(id) {
      this.$router.push(`/application/HW-174G-AVMAO?step=1`);
    }
  }
}
</script>

<style scoped>
.card-body {
  margin-bottom: 10px;
}
hr {
  margin-bottom : -2rem;
}
</style>
