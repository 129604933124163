<template>
  <div class="container user-edit mt-5">
    <div class="mb-5 ">
      <h1
        class="text-center"
        :class="selectedTitle.class"
        :style="selectedTitle.style"
      >
        {{ selectedTitle.text }}
      </h1>
      <h4
        class="text-center"
        :class="selectedSubTitle.class"
        :style="selectedSubTitle.style"
      >
        {{ selectedSubTitle.text }}
      </h4>
    </div>
    <user-form-fields
      @update-success="redirectToBuildingLists()"
      :isFulFillSync="isFulFillSync"
      :detailVerificationStep="detailVerificationStep"
    />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import router from '../../router/index';
import UserFormFields from '../../components/UserFormFields.vue';

export default {
  name: 'UserEdit',

  components: {
    UserFormFields,
  },

  data() {
    return {
      selectedTitle: '',
      selectedSubTitle: '',
    }
  },

  created() {
    if(this.emailVerificationStep){
      router.push({ name: 'fetch-account-details' });
    } else if(this.detailVerificationStep) {
      this.selectedTitle = this.settings.attributes.title3;
      this.selectedSubTitle = this.settings.attributes.subtitle3;
    } else if(this.isFulFillSync) {
      this.selectedTitle = this.settings.attributes.title2;
      this.selectedSubTitle = this.settings.attributes.subtitle2;
    } else {
      this.selectedTitle = this.settings.attributes.title;
      this.selectedSubTitle = this.settings.attributes.subtitle;
    }
  },

  computed: {
    partner() {
      return this.$store.state.partners.currentPartner;
    },

    settings() {
      return this.partner.settings.sections.user_edit_page;
    },

    isFulFillSync() {
      return this.$store.state.users.currentUser.extra_info?.is_fulfill_sync;
    },

    emailVerificationStep() {
      return this.$store.state.users.currentUser.extra_info?.verification_step === 'email_search';
    },

    detailVerificationStep() {
      return this.$store.state.users.currentUser.extra_info?.verification_step === 'detail_search';
    }
  },

  methods: {
    redirectToBuildingLists() {
      this.$store.dispatch('buildings/setNew').then(() => {
        router.push({ name: 'buildings-list' });
      });
    },
  },
};
</script>
