import Vue from 'vue';
import { BootstrapVue } from 'bootstrap-vue';
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { ToggleButton } from 'vue-js-toggle-button';
import Notifications from 'vue-notification';

// Vee Validate
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/en.json';

// V-Mask
import VueMask from 'v-mask';

import $ from 'jquery';
import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels';
import CapitalizeFilter from '@/shared/filters/capitalize';
import TitleizeFilter from '@/shared/filters/titleize';
import App from './App.vue';
import router from './router';
import './registerServiceWorker';

import store from './store';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

// project icons
import '@/shared/mixins/project-icons';

import '@/shared/mixins/project-validations';

global.$ = $;

Vue.prototype.$http = axios;

// Install BootstrapVue
Vue.use(BootstrapVue);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('ToggleButton', ToggleButton);

Vue.config.productionTip = false;

Vue.use(Notifications);

// Use Vee Validate
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
// Available rules: https://vee-validate.logaretm.com/v2/guide/rules.html#validation-rules
// Define custom error messages here
const custommErrorMessages = {
  required: 'This field is required.',
  digits: 'Must be {length} digits.',
  min: 'This field must be at least {length} characters.',
};
Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule],
    message: custommErrorMessages[rule] || messages[rule],
  });
});

// Use V-Mask
Vue.use(VueMask);

Vue.component('ChartJsPluginDataLabels', ChartJsPluginDataLabels);

Vue.filter('capitalize', CapitalizeFilter);
Vue.filter('titleize', TitleizeFilter);

global.loginAsToken = '';

store.dispatch('partners/get').then(() => {
  console.log('Partner settings loaded!!');

  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
});
