<template>
  <div class="home">
    <div v-for="item in homepage" :key="item.id">
      <template v-if="!!item.iframe">
        <iframe-section :section="item" />
      </template>
      <div
        class="full-width home__grid"
        :class="item.background_color"
        :style="item.background_style"
        v-else
      >
        <div class="container inner-wrapper">
          <div class="row">
            <div
              v-if="heroImagePresent(item)"
              class="col-4 d-none d-md-block align-self-center"
            >
              <img
                alt=""
                :src="item.hero_image"
                class="hero-image"
              >
            </div>
            <div class="ml-auto mr-auto align-self-center" :class="dynamicColClass" >
              <h1
                class="ml-auto mr-auto text-center mb-5"
                :class="item.title.class"
                :style="item.title.style"
              >
                {{ item.title.content }}
              </h1>
              <div v-if="item.images.length" class="d-flex justify-content-center">
                <div v-for="image in item.images" :key="image.id" class="m-2 m-s-3 m-md-5">
                  <img :src="image">
                </div>
              </div>
              <span
                class="content-max-width justify-content-center d-flex text-center mt-5 mb-5"
                :class="item.copy.class"
                :style="item.copy.style"
              >
                {{ item.copy.content }}
              </span>
              <button
                class="content-max-width ml-auto mr-auto d-flex btn btn-lg"
                :class="item.cta.class"
                :style="item.cta.style"
                @click="getStartedCta(item)"
              >
                {{ item.cta.content }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import iframeSection from '@/components/iframeSection.vue';
import emptyObjectMixin from '../shared/mixins/empty-object';

export default {
  name: 'homePage',

  mixins: [emptyObjectMixin],

  components: {
    iframeSection,
  },

  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },

  created() {
    if (!this.emptyObject(this.currentUser)) {
      this.$store.dispatch('buildings/setNew');
    }
    window.addEventListener('resize', this.updateWindowWidth);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.updateWindowWidth);
  },

  computed: {
    partner() {
      return this.$store.state.partners.currentPartner;
    },

    currentUser() {
      return this.$store.state.users.currentUser;
    },

    homepage() {
      return this.partner.settings.sections.homepage.homepage_sections;
    },
    dynamicColClass() {
      return this.windowWidth <= 768 ? 'col-11' : 'col-8';
    },
  },

  methods: {
    getStartedCta(item) {
      if (!this.emptyObject(this.currentUser)) {
        this.$router.push({ name: 'buildings-list' });
      } else {
        this.$router.push({ name: item.cta.route });
      }
    },

    heroImagePresent(section) {
      return !!section.hero_image;
    },

    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
  },
};

</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/components/home.scss';
</style>
