export default {
  header: {
    title: 'Home warming Program',
    subtitle: "Free energy upgrades for Nova Scotia homeowners",
    description: 'Eligible Nova Scotia homeowners can receive a free energy-saving upgrades like heat pumps, insulation, and draft-proofing through the HomeWarming program. Save on heating bills and enjoy a cozier home!'
  },
  stepContent: [
    {
      title: 'Step 1 of 4: Select primary Contact',
      subtitle: 'Assign a primary contact by selecting from the list or adding a new one.',
      description: 'This step helps you assign a primary contact for your application. You can select from existing contacts, create a new one or update an existing contact to ensure the right person is linked to your application.',
      buttons: [
        { label: 'Next', url: '/next-step?step=2' },
        { label: 'Previous', url: '/previous-step?step=1' }
      ]
    },
    {
      title: 'Step 2 of 4: Another Step',
      subtitle: 'Subtitle for step 2.',
      description: 'Description for step 2.',
      buttons: [
        { label: 'Next', url: '/next-step?step=3' },
        { label: 'Previous', url: '/previous-step?step=1' }
      ]
    },
    {
      title: 'Step 3 of 4: Another Step',
      subtitle: 'Subtitle for step 3.',
      description: 'Description for step 3.',
      buttons: [
        { label: 'Next', url: '/next-step?step=4' },
        { label: 'Previous', url: '/previous-step?step=2' }
      ]
    },
    {
      title: 'Step 4 of 4: Final Step',
      subtitle: 'Subtitle for step 4.',
      description: 'Description for step 4.',
      buttons: [
        { label: 'Next', url: '/next-step?step=4' },
        { label: 'Previous', url: '/previous-step?step=3' }
      ]
    }
  ],
  application: {
    header: {
      title: 'My Applications',
      subtitle: 'Application Overview',
      description: 'Review or update your existing applications and projects. For assistance, feel free to <span class="egx-partner-color">contact us</span>'
    },
    applications: [
      {
        title: 'Moderate Income Rebate Home Energy Assessment and Oil to Heat Pump affordability Grant',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'HomeWarming',
        id: '460290',
        status: 'Eligibility Review',
        description: 'Thank you! Your application has been received and awaiting  review by our  team. If there is an issue or missing information, someone will reach out to via email or phone.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'HomeWarming',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'Moderate income rebate home energy assessment',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'HomeWarming',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'Moderate income rebate home energy assessment',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'HomeWarming',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'Moderate income rebate home energy assessment',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'HomeWarming',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'Moderate income rebate home energy assessment',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'HomeWarming',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'Moderate income rebate home energy assessment',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'HomeWarming',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'Moderate income rebate home energy assessment',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'HomeWarming',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'Moderate income rebate home energy assessment',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'HomeWarming',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'Moderate income rebate home energy assessment',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'HomeWarming',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'Moderate income rebate home energy assessment',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'HomeWarming',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'Moderate income rebate home energy assessment',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'HomeWarming',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'Moderate income rebate home energy assessment',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      }
    ]
  },
  contactHeader: {
    title: 'Select Primary Contact',
    subtitle: 'Manage or Create the Primary Contact for your Application',
    description: 'Please select an existing Primary contact for this application. If the contact is not available in the dropdown list, please create a new contact by clicking "Add New". Please select "Edit Contact" to update an existing contact, if necessary.'
  },
  contacts: [
    { id: 1, name: 'John Doe' },
    { id: 2, name: 'Jane Doe' }
  ]
};