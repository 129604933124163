<template>
  <div>
    <ApplicationShow :step="step" />
  </div>
</template>

<script>
import ApplicationShow from '../components/ApplicationShow.vue';

export default {
  components: {
    ApplicationShow
  },
  data() {
    return {
      step: parseInt(this.$route.query.step) || 1
    };
  }
}
</script>
