<template>
  <div class="m-4 text-center" v-if="isVisible">
    <font-awesome-icon
      :icon="['fa', 'circle-notch']"
      class="fa-spin m-auto spinner fa-4x egx-partner-color"
    />
  </div>
  <div :class="['card', { 'no-card': disableCard }, 'mb-4']" v-else>
    <div :class="['card-body', { 'no-card': disableCard }]">
      <span class="egx-partner-color">
        <h2>{{ header.title }}</h2>
      </span>
      <h3 class="egx-partner-color">{{ header.subtitle }}</h3>
      <p>{{ header.description }}</p>
      <div class="form-group row align-items-center">
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <label for="primaryContact" class="mb-0">Primary Contact</label>
        </div>
        <div class="col-12 col-md-4 mb-2 mb-md-0">
          <select class="form-control" id="primaryContact" v-model="selectedContactId" @change="populateForm">
            <option disabled selected value="">Select Contact</option>
            <option v-for="contact in contacts" :key="contact.id" :value="contact.id">{{ getContactName(contact.fields) }}</option>
          </select>
        </div>
        <div class="col-12 col-md-6 d-flex justify-content-md-end justify-content-center align-items-end">
          <button class="btn btn-egx" @click="addNewContact">Add New</button>
        </div>
      </div>
      <div v-if="showForm" class="mt-4">
        <span class="egx-partner-color">
          <h2>Contact overview</h2>
        </span>
        <h3 class="egx-partner-color">Personal information</h3>
        <p>Provide the details required to create a new contact</p>
        <div class="form-group row" v-for="(field, index) in contactFields" :key="index">
          <label :for="field.name" class="col-sm-2 col-form-label">
            {{ field.label }}
            <span v-if="field.required === 'true'" class="text-danger">*</span>
          </label>
          <div class="col-sm-10">
            <validation-provider
              mode="lazy"
              :rules="addValidation(field)"
              v-slot="{ errors }"
            >
              <input
                class="form-control"
                v-if="field.type !== 'TEXTAREA' && field.type !== 'EMAIL' && field.type !== 'PHONE'"
                v-model="newContact[field.name]"
                :placeholder="field.label"
                :disabled="isReadOnly"
                :required="field.required === 'true'"
              />
              <input
                class="form-control"
                type="email"
                name="email"
                v-if="field.type === 'EMAIL'"
                v-model="newContact[field.name]"
                :placeholder="field.label"
                :disabled="isReadOnly"
                :required="field.required === 'true'"
              />
              <input
                class="form-control"
                type="text"
                v-if="field.type === 'PHONE'"
                v-model="newContact[field.name]"
                :placeholder="field.label"
                :disabled="isReadOnly"
                :required="field.required === 'true'"
                @input="formatPhone(field.name)"
              />
              <textarea
                v-else-if="field.type === 'TEXTAREA'"
                class="form-control"
                :id="field.name"
                v-model="newContact[field.name]"
                :placeholder="field.label"
                :disabled="isReadOnly"
                :required="field.required === 'true'"
              ></textarea>
              <span class="msg-error text-danger">{{ errMsg = errors[0] }}</span>
            </validation-provider>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-12 d-flex justify-content-center" v-if="isReadOnly">
            <button type="button" class="btn btn-egx" @click="enableEdit">Edit</button>
          </div>
          <div class="col-12 d-flex justify-content-center" v-else-if="!isReadOnly">
            <button type="button" class="btn btn-egx-white mr-2" @click="cancelForm">Cancel</button>
            <button type="submit" class="btn btn-egx" @click="createContact">{{ selectedContactId ? 'Update' : 'Create' }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mockData from '../mockData';
import LoadingScreen from './LoadingScreen.vue';

export default {
  components: {
    LoadingScreen,
  },
  data() {
    return {
      header: mockData.contactHeader,
      showForm: false,
      newContact: {},
      selectedContactId: '',
      isReadOnly: false,
      loadingScreenContent: {
        title: {
          text: 'Loading Contacts',
          class: [],
        },
        description: {
          text: 'Please wait while we load the contacts',
          class: [],
          style: {},
        },
        spinner: {
          class: ['egx-partner-color'],
          style: {},
        },
        cta: {
          text: '',
          class: ['egx-partner-color'],
          style: {},
          path_name: '',
        },
      },  
      isVisible: false,
      disableCard: false,
      isLoading: false,
    };
  },
  created() {
    this.isVisible = true;
    this.$store.dispatch('contacts/fetchContactsFields').then(() => {
      console.log('Fields created!!');
    });
    this.$store.dispatch('contacts/fetchContacts').then(() => {
      this.isVisible = false;
      if (this.contacts.length === 1) {
        this.selectedContactId = this.contacts[0].id;
        this.populateForm();
      }
      console.log('Contacts loaded!!');
    });
  },
  computed: {
    contacts () {
      return this.$store.state.contacts.contacts || [];
    },
    contactFields() {
      return this.$store.state.contacts.contacts_fields;
    }
  },
  methods: {
    getContactName(fields) {
      let contactFields = fields || [];
      if (!fields) {
        const selectedContact = this.contacts.find(contact => contact.id === this.selectedContactId);
        contactFields = selectedContact.fields;
      }
      const firstName = contactFields.find(field => field.name === 'FirstName')?.value || '';
      const lastName = contactFields.find(field => field.name === 'LastName')?.value || '';
      return `${firstName} ${lastName}`;
    },
    populateForm() {
      const selectedContact = this.contacts.find(contact => contact.id === this.selectedContactId);
      if (selectedContact) {
        this.newContact = {};
        selectedContact.fields.forEach(field => {
          this.newContact[field.name] = field.value;
        });
        this.showForm = true;
        this.isReadOnly = true;
      }
    },
    addNewContact() {
      this.selectedContactId = '';
      this.newContact = {};
      this.contactFields.forEach(field => {
        this.newContact[field.name] = '';
      });
      this.showForm = true;
      this.isReadOnly = false;
    },
    cancelForm() {
      this.showForm = false;
    },
    enableEdit() {
      this.isReadOnly = false;
    },
    createContact() {
      const isFormValid = this.contactFields.every(field => {
        if (field.required === 'true') {
          return this.newContact[field.name] && this.newContact[field.name].trim() !== '';
        }
        return true;
      });

      if (!isFormValid) {
        this.$notify({
          group: 'notice',
          text: 'Please fill in all required fields.',
          duration: 5000,
        });
        return;
      }

      this.isLoading = true;
      const formattedContact = {
        fields: Object.keys(this.newContact).map(key => ({
          name: key,
          value: this.newContact[key]
        }))
      };

      if (this.selectedContactId) {
        const updatePayload = {
          Id: this.selectedContactId,
          fields: formattedContact.fields
        };
        this.$store.dispatch('contacts/updateContact', updatePayload)
          .then(() => {
            this.showForm = true;
            this.selectedContactId = this.selectedContactId;
            this.$notify({
              group: 'notice',
              text: 'Contact Updated successfully',
              duration: 5000,
            });
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {      
            this.isReadOnly = true;
          });
      } else {
        this.$store.dispatch('contacts/createContact', formattedContact)
          .then((createdContact) => {
            this.selectedContactId = createdContact[0].id;
            this.showForm = true;
            this.isReadOnly = true;
            this.$notify({
              group: 'notice',
              text: 'Contact Created successfully',
              duration: 5000,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    addValidation(listItem) {
      const validationList = [];

      if (listItem.required === 'true') {
        validationList.push('required');
      }

      return validationList.join('|');
    },
    formatPhone(fieldName) {
      const phone = this.newContact[fieldName].replace(/\D/g, '');
      const formattedPhone = phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
      this.newContact[fieldName] = formattedPhone.substring(0, 12);
    },
  }
}
</script>
<style scoped>
.no-card {
  border: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
}
</style>